import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import YouTube from 'react-youtube';

import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Fade from '@material-ui/core/Fade';

import { ITEM_IMG_URL, VIDEOS_URL, TEL_NO } from '../config';

import { fetchClearanceItems } from '../actions/items';

import SearchBox from './SearchBox';

import customLabelIcon from '../storage/CUSTOMLABELICON-01.svg';
import madeInUsaIcon from '../storage/MADEINUSAICON-01.svg';
import shippingIcon from '../storage/SHIPPINGICON-01.svg';
import speedyIcon from '../storage/SPEEDYICON-01.svg';
import supportIcon from '../storage/SUPPORTICON-01.svg';

// import './styling/FrontPage.css';

const contents = [
    'dye labels',
    'inkjet labels',
    'thermal labels',
    'sheet-fed labels',
    'totally custom labels',
    'price gun labels',
    'all shapes and sizes',
    'free consulation',
    'free artwork design',
    'free printer support',
    'so much more',
];

export default function FrontPage() {
    const [num, setNum] = useState(0);
    const [counter, setCounter] = useState(2);
    const [indexes, setIndexes] = useState([0, 0, 0]);

    const counterRef = useRef(2);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchClearanceItems());
    }, []);

    const clearanceItems = useSelector((state) => state.items.items);

    useEffect(() => {
        if (
            clearanceItems &&
            clearanceItems.length &&
            (!indexes[1] || counter === 9.125)
        ) {
            setTimeout(() => {
                const newIndexes = [];

                for (let i = 0; i < 3; i++) {
                    newIndexes.push(
                        Math.floor(Math.random() * clearanceItems.length)
                    );
                }

                setIndexes(newIndexes);
            }, 125);
        }
    }, [indexes, clearanceItems, counter]);

    const [testimonials] = useState(
        (() => {
            const testimonials = [
                {
                    name: 'Dr. Danowit',
                    company: 'Danowit Dental',
                    title: 'Owner',
                    image: 'testimonial-danowit.png',
                    imageStyle: {
                        height: 50,
                        marginBottom: 20,
                        width: 'auto',
                    },
                    link: 'https://www.danowitdental.com/',
                    text: 'Kenco Label was an absolute pleasure to work with!  Their dedicated sales and support staff were knowledgeable and professional. The proofing process was quick and easy and I got my order in less than a week. The quality of my labels were excellent and I look forward to placing my next order!',
                },
                {
                    name: 'Tom',
                    company: 'Great Lakes Data Racks & Cabinets',
                    title: 'Senior Manufacturing Design Engineer',
                    image: 'testimonial-great-lakes.png',
                    imageStyle: {
                        height: 50,
                        marginBottom: 20,
                        width: 'auto',
                    },
                    link: 'https://greatcabinets.com/',
                    text: 'It was great working with Kenco Label on custom laser warning labels. Our end user was raked over the coals on pricing and availability with our previous vendor. Between the web upload of artwork, online proofing and even a phone call to follow up, Kenco’s order process was easy and we had labels in our hand in under a week! I’m looking forward to more work with Kenco!',
                },
                {
                    name: 'Ari Benedict',
                    company: 'Benedict Realty Group',
                    title: 'CEO',
                    image: 'brg.png',
                    imageStyle: {
                        height: 50,
                        marginLeft: -10,
                        marginBottom: 20,
                        width: 'auto',
                    },
                    link: 'https://www.brgny.com/home',
                    text: `We love dealing with Kenco and use them for all our labeling need. Great quality, great prices, great people.`,
                },
                {
                    name: 'b',
                    company: '',
                    title: '',
                    image: '',
                    text: `Aaron and the Kenco team are wonderful to work with, and so accommodating. They genuinely care about customer satisfaction, and do everything in their power to fulfill customer needs in a timely fashion. Thank you Kenco for your continued efforts and for being a stand-up company to partner with for labels!`,
                },
                {
                    name: 'c',
                    company: '',
                    title: '',
                    image: '',
                    text: `Pure Joy Naturals has used the Kenco labels for about 5 years now. Our lesson in this is that Kenco is quality. We deal with Aaron or Steve and they have helped us match the perfect label to the product. I have used their new website and it is sooooo much nicer than the old one. It navigates quickly and I get the information that I need really fast. Well done. So thank you to Kenco for helping our family owned company grow. We would highly recommend your family company to others as we have confidence in both your service and products.`,
                },
                {
                    name: 'd',
                    company: '',
                    title: '',
                    image: '',
                    text: `Kenco Label is my go-to source for all things labels. They knock it out of the park every time. Fair pricing and always excellent service!`,
                },
                {
                    name: 'e',
                    company: '',
                    title: '',
                    image: '',
                    text: `Superb quality, quick shipping, perfect size for our packaging. But that's only part of the story. All too often we take customer service for granted, or else just get used to mediocre customer care. Kenco's customer service is amazing—they answered my question almost immediately with an informative and helpful reply. It doesn't get any better.`,
                },
                {
                    name: 'f',
                    company: '',
                    title: '',
                    image: '',
                    text: `Exactly what I needed, and I honestly didn't expect it as fast as I got it. Perfect for my business needs. I will definitely be ordering again.`,
                },
                {
                    name: 'g',
                    company: '',
                    title: '',
                    image: '',
                    text: `These are the BEST stickers I've ever ordered. If you want the best fragile stickers in all of the USA, PLEASE, I urge you to purchase these from THIS company. I promise you will not be disappointed.`,
                },
                {
                    name: 'h',
                    company: '',
                    title: '',
                    image: '',
                    text: `Unbelievable service! I did a search for the items my husband needed and was directed to the Kenco site. I easily found what was needed, ordered and it was at my front door in just a few days. I have been back to the site and ordered other items and will return again and again, as the need arises.`,
                },
                {
                    name: 'i',
                    company: '',
                    title: '',
                    image: '',
                    text: `The labels are of great quality and the time frame is a quick as it can be when the labels are custom. We use Kenco for all of our labeling needs. Thanks as usual!`,
                },
            ];

            return testimonials.map((testimonial) => (
                <Paper
                    key={testimonial.name}
                    variant="outlined"
                    style={{ position: 'relative', padding: 30, flex: 1 }}
                >
                    <a
                        href={testimonial.link}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <img
                            alt=""
                            src={`${ITEM_IMG_URL}${testimonial.image}`}
                            style={{ ...(testimonial.imageStyle || {}) }}
                            className="fp-tes-image"
                        />
                    </a>
                    <div className="fp-tes-item-header">
                        <div>
                            <span className="fp-tes-item-header-name">
                                {testimonial.name}
                            </span>
                            <span className="fp-tes-item-header-title">
                                {' '}
                                · {testimonial.title}
                            </span>
                        </div>
                        {testimonial.link ? (
                            <div className="fp-tes-item-header-company">
                                <a
                                    href={testimonial.link}
                                    target="_blank"
                                    className="blue orange"
                                    rel="noopener noreferrer"
                                >
                                    {testimonial.company}
                                </a>
                            </div>
                        ) : (
                            <div className="fp-tes-item-header-company">
                                {testimonial.company}
                            </div>
                        )}
                    </div>
                    <div className="fp-tes-item-quote">
                        <q className="fp-tes-item-testimonial">
                            {testimonial.text}
                        </q>
                    </div>
                </Paper>
            ));
        })()
    ); // DELETE

    const opacity = (() => {
        const now = Math.floor(counter) % 10;

        if ([3, 6, 9].includes(now)) return 0;
        return 1;
    })();

    const incrementTimer = () => {
        const { current } = counterRef;

        if (current === 36) {
            counterRef.current = 0;
            setCounter(0);
        } else {
            counterRef.current = current + 0.125;
            setCounter(current + 0.125);
        }
    };

    useEffect(() => {
        const timer = setInterval(() => {
            incrementTimer();
        }, 125);

        return () => {
            clearInterval(timer);
        };
    }, []);

    useEffect(() => {
        let time = null;

        if (opacity === 0) {
            time = setTimeout(
                () => setNum(num === contents.length - 1 ? 0 : num + 1),
                350
            );
        }

        return () => clearTimeout(time);
    }, [opacity]);

    // const clearance = indexes.map((index) => {
    //     if (clearanceItems && clearanceItems[index]) {
    //         const clearanceItem = clearanceItems[index];
    //         const { sku, name, images, pricing } = clearanceItem;
    //         const image =
    //             images.find((img) => img.thumbnail) || images[0] || {};
    //         const { imageFile } = image;

    //         const originalPricing = pricing.filter(
    //             (priceObj) => !priceObj.type
    //         );
    //         const clearancePricing = pricing.filter(
    //             (priceObj) => priceObj.type === 'C'
    //         );

    //         const originalPrice = originalPricing[0]
    //             ? originalPricing[0].price
    //             : 0;
    //         const clearancePrice = clearancePricing[0]
    //             ? clearancePricing[0].price
    //             : 0;

    //         const discount =
    //             originalPrice && clearancePrice
    //                 ? `${Math.round(
    //                       (1 - clearancePrice / originalPrice) * 100
    //                   )}%`
    //                 : '';

    //         return (
    //             <Paper
    //                 key={sku}
    //                 variant="outlined"
    //                 style={{ position: 'relative', padding: 30, flex: 1 }}
    //             >
    //                 <div style={{ textAlign: 'center' }}>
    //                     <img
    //                         src={`${ITEM_IMG_URL}${imageFile}`}
    //                         alt=""
    //                         className="fp-clearance-item-img"
    //                     />
    //                 </div>
    //                 <div className="fp-clearance-item-title">{name}</div>
    //                 <div className="fp-clearance-item-pricing">
    //                     <div className="fp-clearance-item-original-price">
    //                         ${originalPrice.toFixed(2)}
    //                     </div>
    //                     <div className="fp-clearance-item-clearance-price">
    //                         ${clearancePrice.toFixed(2)}
    //                         {'\u2002'}
    //                         <div
    //                             className="clearance-page-item-discount"
    //                             style={{ display: 'inline' }}
    //                         >{`${discount} off!`}</div>
    //                     </div>
    //                 </div>
    //             </Paper>
    //         );
    //     }

    //     return <div key={index} />;
    // });

    return (
        <div className="front-page">
            <div className="front-page-contents">
                <div className="background-container">
                    <div className="cta-box">
                        <div className="cta-box-title">
                            Custom
                            <br />
                            Printed Labels
                        </div>
                        <div className="cta-box-divider" />
                        <div className="cta-box-undertitle">
                            Best Price Guarantee
                        </div>
                        <Link to="/build/custom" id="cta-link">
                            <button
                                className="cta-box-button"
                                id="cta-button-configurator"
                            >
                                Get a Quote Now!
                            </button>
                        </Link>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                margin: '0.25rem 0',
                            }}
                            className="hide-mobile"
                        >
                            <div className="cta-box-divider" />
                            <div style={{ margin: '-5px 0.5rem 0' }}>OR</div>
                            <div className="cta-box-divider" />
                        </div>
                        <Link
                            to="/custom-printed-form"
                            id="cta-link"
                            className="hide-mobile"
                        >
                            <button
                                className="cta-box-button"
                                id="cta-button-form"
                            >
                                Quote Request Form
                            </button>
                        </Link>
                    </div>
                    {counter > 1 && (
                        <video
                            autoPlay
                            muted
                            loop
                            className="video hide-mobile"
                        >
                            <source
                                src={`${VIDEOS_URL}bkgrd.mp4`}
                                type="video/mp4"
                            />
                            Your browser does not support the video tag.
                        </video>
                    )}
                    <div className="background-builder">
                        <h1 className="callout">Kenco does it all.</h1>
                        <div className="callout-subtitle">
                            We do{' '}
                            <span
                                className="callout-switcher"
                                style={{ opacity }}
                            >
                                {contents[num]}
                                <span style={{ fontWeight: 400 }}>.</span>
                            </span>
                        </div>
                    </div>
                    <SearchBox />
                </div>
                <div className="background-overlay"></div>
            </div>
            <div className="fp-bottom">
                <section className="fp-hero hide-mobile">
                    <div className="fp-hero-item">
                        <img
                            src={madeInUsaIcon}
                            alt="Made in USA"
                            className="fp-hero-img"
                        />
                        <div className="fp-hero-text">
                            <h3 className="fp-hero-text-header">
                                Made in the USA
                            </h3>
                            <p className="fp-hero-text-p">
                                Our labels are made in-house in Milwaukee, WI.
                            </p>
                        </div>
                    </div>
                    <div className="fp-hero-item">
                        <img
                            src={customLabelIcon}
                            alt="Customizable"
                            className="fp-hero-img"
                        />
                        <div className="fp-hero-text">
                            <h3 className="fp-hero-text-header">
                                Totally Customizable
                            </h3>
                            <p className="fp-hero-text-p">
                                We do all shapes, sizes &amp; art on
                                premium-quality materials.
                            </p>
                        </div>
                    </div>
                    <div className="fp-hero-item">
                        <img
                            src={supportIcon}
                            alt="Free Shipping"
                            className="fp-hero-img"
                        />
                        <div className="fp-hero-text">
                            <h3 className="fp-hero-text-header">
                                Free In-House Support
                            </h3>
                            <p className="fp-hero-text-p">
                                Need artwork help? Having printer troubles?{' '}
                                <a href={`tel:${TEL_NO}`}>Give us a call!</a>
                            </p>
                        </div>
                    </div>
                    <div className="fp-hero-item">
                        <img
                            src={shippingIcon}
                            alt="Free Shipping"
                            className="fp-hero-img"
                        />
                        <div className="fp-hero-text">
                            <h3 className="fp-hero-text-header">
                                Free Standard Shipping
                            </h3>
                            <p className="fp-hero-text-p">
                                Within the continental US, on all online
                                purchases over $250.
                            </p>
                        </div>
                    </div>
                    <div className="fp-hero-item">
                        <img src={speedyIcon} alt="" className="fp-hero-img" />
                        <div className="fp-hero-text">
                            <h3 className="fp-hero-text-header">
                                Speedy Turnaround
                            </h3>
                            <p className="fp-hero-text-p">
                                Kenco has the largest stock inventory of blank
                                &amp; printed labels.
                            </p>
                        </div>
                    </div>
                </section>
                <section className="fp-hero hide-tablet hide-small hide-medium hide-big">
                    <Fade
                        in={counter % 36 >= 2 && counter % 36 < 9}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="fp-hero-item">
                            <img
                                src={madeInUsaIcon}
                                alt="Made in USA"
                                className="fp-hero-img"
                            />
                            <div className="fp-hero-text">
                                <h3 className="fp-hero-text-header">
                                    Made in the USA
                                </h3>
                                <p className="fp-hero-text-p">
                                    Our labels are made in-house in Milwaukee,
                                    WI.
                                </p>
                            </div>
                        </div>
                    </Fade>
                    <Fade
                        in={counter % 36 >= 11 && counter % 36 < 20}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="fp-hero-item">
                            <img
                                src={customLabelIcon}
                                alt="Customizable"
                                className="fp-hero-img"
                            />
                            <div className="fp-hero-text">
                                <h3 className="fp-hero-text-header">
                                    Totally Customizable
                                </h3>
                                <p className="fp-hero-text-p">
                                    We do all shapes, sizes &amp; art on
                                    premium-quality materials.
                                </p>
                            </div>
                        </div>
                    </Fade>
                    <Fade
                        in={counter % 36 >= 22 && counter % 36 < 31}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="fp-hero-item">
                            <img
                                src={supportIcon}
                                alt="Free Shipping"
                                className="fp-hero-img"
                            />
                            <div className="fp-hero-text">
                                <h3 className="fp-hero-text-header">
                                    Free In-House Support
                                </h3>
                                <p className="fp-hero-text-p">
                                    Need artwork help? Having printer troubles?{' '}
                                    <a href={`tel:${TEL_NO}`}>
                                        Give us a call!
                                    </a>
                                </p>
                            </div>
                        </div>
                    </Fade>
                    <Fade
                        in={counter % 36 >= 33 || counter === 0}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="fp-hero-item">
                            <img
                                src={shippingIcon}
                                alt="Free Shipping"
                                className="fp-hero-img"
                            />
                            <div className="fp-hero-text">
                                <h3 className="fp-hero-text-header">
                                    Free Standard Shipping
                                </h3>
                                <p className="fp-hero-text-p">
                                    Within the continental US, on all online
                                    purchases over $250.
                                </p>
                            </div>
                        </div>
                    </Fade>
                </section>
                <section className="fp-hero hide-small-mobile hide-small hide-medium hide-big">
                    <Fade
                        in={counter % 36 >= 2 && counter % 36 < 20}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="fp-hero-item">
                            <img
                                src={madeInUsaIcon}
                                alt="Made in USA"
                                className="fp-hero-img"
                            />
                            <div className="fp-hero-text">
                                <h3 className="fp-hero-text-header">
                                    Made in the USA
                                </h3>
                                <p className="fp-hero-text-p">
                                    Our labels are made in-house in Milwaukee,
                                    WI.
                                </p>
                            </div>
                        </div>
                    </Fade>
                    <Fade
                        in={counter % 36 >= 2 && counter % 36 < 20}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="fp-hero-item">
                            <img
                                src={customLabelIcon}
                                alt="Customizable"
                                className="fp-hero-img"
                            />
                            <div className="fp-hero-text">
                                <h3 className="fp-hero-text-header">
                                    Totally Customizable
                                </h3>
                                <p className="fp-hero-text-p">
                                    We do all shapes, sizes &amp; art on
                                    premium-quality materials.
                                </p>
                            </div>
                        </div>
                    </Fade>
                    <Fade
                        in={counter % 36 >= 22 || counter === 0}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="fp-hero-item">
                            <img
                                src={supportIcon}
                                alt="Free Support"
                                className="fp-hero-img"
                            />
                            <div className="fp-hero-text">
                                <h3 className="fp-hero-text-header">
                                    Free In-House Support
                                </h3>
                                <p className="fp-hero-text-p">
                                    Need artwork help? Having printer troubles?{' '}
                                    <a href={`tel:${TEL_NO}`}>
                                        Give us a call!
                                    </a>
                                </p>
                            </div>
                        </div>
                    </Fade>
                    <Fade
                        in={counter % 36 >= 22 || counter === 0}
                        mountOnEnter
                        unmountOnExit
                    >
                        <div className="fp-hero-item">
                            <img
                                src={shippingIcon}
                                alt="Free Shipping"
                                className="fp-hero-img"
                            />
                            <div className="fp-hero-text">
                                <h3 className="fp-hero-text-header">
                                    Free Standard Shipping
                                </h3>
                                <p className="fp-hero-text-p">
                                    Within the continental US, on all online
                                    purchases over $250.
                                </p>
                            </div>
                        </div>
                    </Fade>
                </section>
                <Divider style={{ width: '100vw' }} />
                <div>
                    <div className="fp-bottom-grid">
                        <section
                            className="fp-bottom-section fp-hiw"
                            style={{ minHeight: 500 }}
                        >
                            <h2 className="fp-bottom-section-header fp-tes-header">
                                Testimonials
                            </h2>
                            <Fade
                                in={counter % 36 >= 1 && counter % 36 < 12}
                                mountOnEnter
                                unmountOnExit
                            >
                                <div className="fp-tes-items">
                                    {testimonials[0]}
                                </div>
                            </Fade>
                            <Fade
                                in={counter % 36 >= 13 && counter % 36 < 24}
                                mountOnEnter
                                unmountOnExit
                            >
                                <div className="fp-tes-items">
                                    {testimonials[1]}
                                </div>
                            </Fade>
                            <Fade
                                in={counter % 36 >= 25 && counter % 36 < 36}
                                mountOnEnter
                                unmountOnExit
                            >
                                <div className="fp-tes-items">
                                    {testimonials[2]}
                                </div>
                            </Fade>
                        </section>
                        <section
                            className="fp-bottom-section fp-hiw"
                            style={{ minHeight: 500 }}
                        >
                            <h2 className="fp-bottom-section-header fp-tes-header">
                                YouTube Clips{'\u2002'}
                                <a
                                    href="https://www.youtube.com/c/Kencolabel"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <i class="fas fa-external-link-alt"></i>
                                </a>
                            </h2>
                            <Fade
                                in={counter % 36 >= 4 && counter % 36 < 15}
                                mountOnEnter
                                unmountOnExit
                            >
                                <div className="fp-tes-items">
                                    <YouTube
                                        videoId="7z1YhSHwHsQ"
                                        opts={{ width: '100%' }}
                                    />
                                </div>
                            </Fade>
                            <Fade
                                in={counter % 36 >= 16 && counter % 36 < 27}
                                mountOnEnter
                                unmountOnExit
                            >
                                <div className="fp-tes-items">
                                    <YouTube
                                        videoId="xpdemY5GuFQ"
                                        opts={{ width: '100%' }}
                                    />
                                </div>
                            </Fade>
                            <Fade
                                in={counter % 36 >= 28 || counter % 36 < 3}
                                mountOnEnter
                                unmountOnExit
                            >
                                <div className="fp-tes-items">
                                    <YouTube
                                        videoId="NaLjj3_ujmA"
                                        opts={{ width: '100%' }}
                                    />
                                </div>
                            </Fade>
                        </section>
                        <section
                            className="fp-bottom-section fp-hiw"
                            style={{ minHeight: 500 }}
                        >
                            <Link to="/services">
                                <h2 className="fp-bottom-section-header fp-tes-header">
                                    Services
                                </h2>
                            </Link>
                            <Fade
                                in={counter % 36 >= 7 && counter % 36 < 18}
                                mountOnEnter
                                unmountOnExit
                            >
                                <Link
                                    to="/try"
                                    style={{
                                        fontSize: '3rem',
                                        fontWeight: 700,
                                    }}
                                >
                                    <Paper
                                        variant="outlined"
                                        style={{
                                            position: 'relative',
                                            display: 'flex',
                                            padding: 30,
                                            flex: 1,
                                            height: 300,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontFamily:
                                                'IBM Plex Sans Condensed',
                                        }}
                                    >
                                        <div>
                                            <span className="blue orange">
                                                Try Kenco®
                                            </span>
                                            <p
                                                style={{
                                                    fontSize: '2rem',
                                                    fontWeight: 400,
                                                }}
                                            >
                                                Try before you buy—Get your
                                                first order free!
                                            </p>
                                        </div>
                                    </Paper>
                                </Link>
                            </Fade>
                            <Fade
                                in={counter % 36 >= 19 && counter % 36 < 30}
                                mountOnEnter
                                unmountOnExit
                            >
                                <Link
                                    to="/lp"
                                    style={{
                                        fontSize: '3rem',
                                        fontWeight: 700,
                                    }}
                                >
                                    <Paper
                                        variant="outlined"
                                        style={{
                                            position: 'relative',
                                            display: 'flex',
                                            padding: 30,
                                            flex: 1,
                                            height: 300,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontFamily:
                                                'IBM Plex Sans Condensed',
                                        }}
                                    >
                                        <div>
                                            <span className="blue orange">
                                                Kenco-LP®
                                            </span>
                                            <p
                                                style={{
                                                    fontSize: '2rem',
                                                    fontWeight: 400,
                                                }}
                                            >
                                                The future in loss prevention,
                                                secure ID badges, and product
                                                tracking begins with Kenco-LP®.
                                            </p>
                                        </div>
                                    </Paper>
                                </Link>
                            </Fade>
                            <Fade
                                in={counter % 36 >= 31 || counter % 36 < 6}
                                mountOnEnter
                                unmountOnExit
                            >
                                <Link
                                    to="/"
                                    style={{
                                        fontSize: '3rem',
                                        fontWeight: 700,
                                    }}
                                >
                                    <Paper
                                        variant="outlined"
                                        style={{
                                            position: 'relative',
                                            display: 'flex',
                                            padding: 30,
                                            flex: 1,
                                            height: 300,
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            fontFamily:
                                                'IBM Plex Sans Condensed',
                                        }}
                                    >
                                        <div>
                                            <span className="blue orange">
                                                Repairs
                                            </span>
                                            <p
                                                style={{
                                                    fontSize: '2rem',
                                                    fontWeight: 400,
                                                }}
                                            >
                                                Kenco is pleased to offer label
                                                gun repair services!
                                            </p>
                                        </div>
                                    </Paper>
                                </Link>
                            </Fade>
                        </section>
                        {/* <section
                            className="fp-bottom-section fp-hiw"
                            style={{ minHeight: 500 }}
                        >
                            <Link to="/clearance">
                                <h2 className="fp-bottom-section-header fp-tes-header">
                                    Clearance
                                </h2>
                            </Link>
                            <Fade
                                in={counter % 36 >= 10 && counter % 36 < 21}
                                mountOnEnter
                                unmountOnExit
                            >
                                <Link to="/clearance">
                                    <div className="fp-tes-items">
                                        {clearance[0]}
                                    </div>
                                </Link>
                            </Fade>
                            <Fade
                                in={counter % 36 >= 22 && counter % 36 < 33}
                                mountOnEnter
                                unmountOnExit
                            >
                                <Link to="/clearance">
                                    <div className="fp-tes-items">
                                        {clearance[1]}
                                    </div>
                                </Link>
                            </Fade>
                            <Fade
                                in={counter % 36 >= 34 || counter % 36 < 9}
                                mountOnEnter
                                unmountOnExit
                            >
                                <Link to="/clearance">
                                    <div className="fp-tes-items">
                                        {clearance[2]}
                                    </div>
                                </Link>
                            </Fade>
                        </section> */}
                    </div>
                </div>
            </div>
        </div>
    );
}
